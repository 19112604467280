.background {
  min-height: calc(100vh - 80px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.fadeIn {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.firstGradient {
  background: linear-gradient(45deg, rgba(229, 220, 214, 1) 0%, rgba(242, 239, 229, 1) 100%);
}

.secondGradient {
  background: linear-gradient(135deg, rgba(248, 197, 190, 1) 0%, rgba(253, 234, 232, 1) 100%);
}

.thirdGradient {
  background: linear-gradient(45deg, rgba(230, 244, 241, 1) 0%, rgba(185, 223, 216, 1) 100%);
}

.fourthGradient {
  background: linear-gradient(135deg, rgba(242, 241, 248, 1) 0%, rgba(204, 200, 228, 1) 100%);
}
