.registerFormPlan {
    position: relative;
    padding: 1.25rem 0;
    list-style: none;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ced4d6;
  }
  
  .registerFormPlan input[type="radio"] {
    display: none; /* Hide the default radio button */
  }
  
  .registerFormPlan label {
    display: block;
    position: relative;
    padding-left: 50px; /* Leave space for the custom radio button */
    cursor: pointer;
    width: 100%;
  }
  
  .registerFormPlan label .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  .registerFormPlan label .flex .price-pill {
    background-color: #ffe146;
    color: #1e1e28;
    font-size: 0.875rem; /* Small text */
    font-weight: bold;
    padding: 0.2rem 0.6rem;
    border-radius: 20px;
    text-align: center;
    display: inline-block;
  }
  
  .registerFormPlan label .description {
    font-size: 0.75rem; /* Small description text */
    color: #6b7280; /* Gray color */
    line-height: 1.25rem;
    margin-top: 0.25rem;
  }
  
  .registerFormPlan label::before {
    content: "";
    position: absolute;
    top: 16px;
    left: 0;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    border: 2px solid #badbd9;
    background-color: white;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }
  
  .registerFormPlan label::after {
    content: "";
    position: absolute;
    top: 16px;
    left: 7px;
    transform: translateY(-50%) scale(0);
    width: 21px;
    height: 21px;
    background-color: #badbd9;
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
  }
  
  .registerFormPlan input[type="radio"]:checked + label::after {
    transform: translateY(-50%) scale(1); /* Scale the inner circle when checked */
  }
  
  .registerFormPlan input[type="radio"]:checked + label::before {
    border-color: #badbd9; /* Change border color when selected */
  }
  